import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './LoginComponent.css'; // Assuming you have a separate CSS file for styling

const LoginComponent = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage(''); // Clear previous messages
    setLoading(true); // Set loading state

    // Use the Promise method
    axios.post('https://mypocketprofit.com:5000/login', {
      email: email,  // Send email and password
      password: password,
    })
    .then(response => {
      const { token } = response.data; // Extract token from response
      setMessage('Login successful');
      onLoginSuccess(token); // Pass token to parent
      navigate('/chart'); // Navigate to the chart page
    })
    .catch(error => {
      const errorMsg = error.response?.data?.message || 'Invalid login credentials';
      setMessage(errorMsg); // Display error message from the server
    })
    .finally(() => {
      setLoading(false); // Reset loading state
    });
  };

  return (
    <div className="login-container">
      <h2>Client Login</h2>
      <form onSubmit={handleSubmit}>
        <input 
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          placeholder="Email" 
          required 
        />
        <input 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          placeholder="Password" 
          required 
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>
      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default LoginComponent;
