import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';

const isPinBar = (candle) => {
  const bodySize = Math.abs(candle.open - candle.close);
  const wickSize = Math.abs(candle.high - candle.low);
  const upperWick = candle.high - Math.max(candle.open, candle.close);
  const lowerWick = Math.min(candle.open, candle.close) - candle.low;

  return (
    wickSize > 2 * bodySize &&
    (upperWick > 2 * bodySize || lowerWick > 2 * bodySize)
  );
};

const TradingChart = ({ onLogout }) => {
  const chartRef = useRef(null);
  const [candleSeries, setCandleSeries] = useState(null);
  const [market, setMarket] = useState('R_100');
  const [timeframe, setTimeframe] = useState(60);
  const [latestCandle, setLatestCandle] = useState(null);
  const [candleHistory, setCandleHistory] = useState([]);

  // Initialize chart
  useEffect(() => {
    const chart = createChart(chartRef.current, {
      width: chartRef.current.clientWidth,
      height: window.innerHeight - 100,
      layout: {
        backgroundColor: '#FFFFFF',
        textColor: '#000',
      },
      grid: {
        vertLines: { color: '#e1e1e1' },
        horzLines: { color: '#e1e1e1' },
      },
      priceScale: {
        borderColor: '#cccccc',
      },
      timeScale: {
        borderColor: '#cccccc',
        timeVisible: true,
        secondsVisible: false,
      },
      crosshair: {
        mode: 1,
      },
    });

    const series = chart.addCandlestickSeries({
      upColor: '#4caf50',
      downColor: '#ef5350',
      borderUpColor: '#4caf50',
      borderDownColor: '#ef5350',
      wickUpColor: '#4caf50',
      wickDownColor: '#ef5350',
    });

    setCandleSeries(series);

    const handleResize = () => {
      chart.applyOptions({
        width: chartRef.current.clientWidth,
        height: window.innerHeight - 100,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, []);

  // Handle WebSocket connection and data
  useEffect(() => {
    if (!candleSeries) return;

    const ws = new WebSocket('wss://ws.binaryws.com/websockets/v3?app_id=1089');

    ws.onopen = () => {
      ws.send(JSON.stringify({
        ticks_history: market,
        adjust_start_time: 1,
        count: 1000,
        end: 'latest',
        start: 1,
        style: 'candles',
        subscribe: 1,
        granularity: timeframe
      }));
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.candles) {
        const formattedData = data.candles
          .map(candle => ({
            time: candle.epoch,
            open: candle.open,
            high: candle.high,
            low: candle.low,
            close: candle.close
          }))
          .sort((a, b) => a.time - b.time);

        setCandleHistory(formattedData);
        candleSeries.setData(formattedData);
        setLatestCandle(formattedData[formattedData.length - 1]);

        // Generate signals
        const signals = formattedData
          .filter(candle => isPinBar(candle))
          .map(candle => ({
            time: candle.time,
            position: candle.close > candle.open ? 'belowBar' : 'aboveBar',
            color: candle.close > candle.open ? 'green' : 'red',
            shape: candle.close > candle.open ? 'arrowUp' : 'arrowDown',
            text: candle.close > candle.open ? 'BUY' : 'SELL'
          }));

        candleSeries.setMarkers(signals);
      }

      // Update latest candle in real-time
      if (data.tick && latestCandle) {
        const updatedCandle = {
          ...latestCandle,
          high: Math.max(latestCandle.high, data.tick.quote),
          low: Math.min(latestCandle.low, data.tick.quote),
          close: data.tick.quote,
          time: data.tick.epoch
        };

        setLatestCandle(updatedCandle);
        candleSeries.update(updatedCandle);
      }
    };

    return () => ws.close();
  }, [candleSeries, market, timeframe, latestCandle]);

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Live Trading Chart</h2>
      
      {/* Market Controls */}
      <div className="flex justify-between mb-4">
        <select 
          value={market} 
          onChange={(e) => setMarket(e.target.value)}
          className="p-2 border rounded"
        >
          <option value="R_100">Volatility 100 Index</option>
          <option value="R_75">Volatility 75 Index</option>
          <option value="R_50">Volatility 50 Index</option>
          <option value="R_25">Volatility 25 Index</option>
          <option value="R_10">Volatility 10 Index</option>
          <option value="R_100_1S">Volatility 100 (1s) Index</option>
          <option value="R_75_1S">Volatility 75 (1s) Index</option>
          <option value="R_50_1S">Volatility 50 (1s) Index</option>
          <option value="R_25_1S">Volatility 25 (1s) Index</option>
          <option value="R_10_1S">Volatility 10 (1s) Index</option>
        </select>

        <select 
          value={timeframe} 
          onChange={(e) => setTimeframe(Number(e.target.value))}
          className="p-2 border rounded"
        >
          <option value="1">1 Second</option>
          <option value="60">1 Minute</option>
          <option value="120">2 Minutes</option>
          <option value="300">5 Minutes</option>
          <option value="600">10 Minutes</option>
          <option value="900">15 Minutes</option>
          <option value="1800">30 Minutes</option>
          <option value="3600">1 Hour</option>
          <option value="7200">2 Hours</option>
          <option value="14400">4 Hours</option>
          <option value="18000">5 Hours</option>
        </select>
      </div>

      {/* Chart Container */}
      <div ref={chartRef} className="rounded-lg shadow-lg" />

      {/* Logout Button */}
      <button onClick={onLogout} className="logout-button">
        Logout
      </button>
    </div>
  );
};

export default TradingChart;

