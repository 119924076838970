import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';

function HomePage() {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    document.body.classList.toggle('dark-mode', darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(prevMode => !prevMode);
  };

  const backgroundImage = `${process.env.PUBLIC_URL}/images/candles.jpg`;

  return (
    <div className={`home-page ${darkMode ? 'dark-mode' : ''}`} style={{backgroundImage: `url(${backgroundImage})`}}>
      <div className="top-right-buttons">
        <button onClick={toggleDarkMode} className="toggle-icon" aria-label="Toggle dark mode">
          {darkMode ? '☀️' : '🌙'}
        </button>
        <Link to="/login" className="login-button">Login</Link>
      </div>
      <div className="content">
        <h1>Welcome to Our Deriv Charts Indicator</h1>
        <p>The Indicator is a real-time tool for volatility indices on Deriv, offering buy and sell signals with a 99% win rate. It uses live candlestick data and updates automatically, helping traders identify accurate entry points for trading digits.</p>
        <ul>
          <li>- Live candlestick data</li>
          <li>- Automatic updates</li>
          <li>- Buy and sell signals</li>
        </ul>
        <div className="cta-buttons">
          <Link to="/login" className="cta-button">Get Started</Link>
          <Link to="https://hub.deriv.com/tradershub/signup" className="cta-button deriv-signup">Deriv Signup</Link>
        </div>
      </div>
      <div className="bottom-right-message">
        The products offered on our website are complex derivative products that carry a significant risk of potential loss. CFDs are complex instruments with a high risk of losing money rapidly due to leverage. You should consider whether you understand how these products work and whether you can afford to take the high risk of losing your money.
      </div>
      <div className="powered-by-deriv">
        <img src={`${process.env.PUBLIC_URL}/images/deriv-icon.png`} alt="Deriv Icon" className="deriv-icon" />
        <span>Powered by Deriv</span>
      </div>
    </div>
  );
}

export default HomePage;
